import React, { useEffect, useState, useCallback, useRef } from "react";
// import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { 
  AlertModal,
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link, 
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input } from "stories/forms";
import {
  httpGetCustomerCampaigns,
} from "services/customer_campaigns"
import {
  httpCreatePurchaseOrder,
  httpUpdatePurchaseOrder,
  httpDeletePurchaseOrder,
  httpGetPurchaseOrder,
  httpGetPurchaseOrderCustomerCampaignProducts,
  httpSendRequestForQuotation,
  httpSendAnOfferPurchaseOrder,
  httpConfirmPurchaseOrder,
} from "services/purchase_orders"
import {
  httpGetCustomerEmails,
} from "services/customers"
import {
  httpGetSellerEmails,
} from "services/companies"
import TableColumnToggle from "components/TableColumnToggle/TableColumnToggle"
import { useNotification } from "stories/components/Notification"

const PriceListOrder = ({history, match}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();
  const [controller, setController] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [orderInstance, setOrderInstance] = useState();
  const [customerCampaigns, setCustomerCampaigns] = useState() // Customer campaigns == Price lists
  const [amountsCopied, setAmountsCopied] = useState(false);
  const [customerEmails, setCustomerEmails] = useState([]);
  const [sellerEmails, setSellerEmails] = useState([]);
  const [loading, setLoading] = useState(0)
  const [archivedBySeller, setArchivedBySeller] = useState(false);

  const getCustomerEmails = (customerId) => {
    httpGetCustomerEmails(customerId).then(res => {
      setCustomerEmails(res?.data)
    }, error => {
      console.error('httpGetCustomerEmails error:', error);
    })
  }

  const getSellerEmails = () => {
    httpGetSellerEmails().then(res => {
      setSellerEmails(res?.data)
    }, error => {
      console.error('httpGetSellerEmails error:', error);
    })
  }

  const [rows, setRows] = useState([])

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "name",
    creators: [],
    customer_campaigns: [],

  })
  const [filterOptions, setFilterOptions] = useState({
    creators: [
      {value: 1, label: "1#Aapinen"},
      {value: 2, label: "2#Bobius"},
    ],
  })

  const [orderProductsAnalytics, setOrderProductsAnalytics] = useState({});

  const [confirmAction, setConfirmAction] = useState();
  const cancelConfirmAction = () => {
    setConfirmAction(null)
  }

  const [deleteAlert, setDeleteAlert] = useState();

  useEffect(() => {
    const _orderId = match?.params?.orderId;
    if (_orderId) {
      console.log("_orderId", _orderId)
      setOrderId(_orderId)
    }
  }, [match])

  useEffect(() => {
    // Create new order if orderId is "new"

    if (orderId && orderId === "new" && customerCampaigns && customerCampaigns.length > 0) {
      // List of customer campaigns ids
      let customerCampaignIds = [];
      customerCampaigns.map(obj => {
        customerCampaignIds.push(obj?.value)
      })

      const data = {
        customer_campaigns: customerCampaignIds,
        order_data: [],
        purchase_order_customer_campaigns: [],
      }
      // Create new order
      setLoading(s => s+1)
      httpCreatePurchaseOrder(data).then(res => {
        console.log("httpCreatePurchaseOrder", res)
        // setOrderId(res?.data?.id)
        history.push(`/dashboard/price_list_orders/order/${res?.data?.id}`);
      }, error => {
        console.error('httpCreatePurchaseOrder error:', error);
      }).finally(() => {
        setLoading(s => s-1)
      })

    }
  }, [orderId, customerCampaigns])

  /*
  useEffect(() => {
    // Fetch data when the component mounts

    // Cleanup function to cancel the request if the component unmounts
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);
  */

  useEffect(() => {
    let _analytics = {
      price_list_products_count: 0,
      price_list_variation_products_count: 0,
    };
    rows && rows.map(row => {
      if (row?.product_variation_fk) {
        _analytics["price_list_variation_products_count"] += 1;
      }
      else {
        _analytics["price_list_products_count"] += 1;
      }
    })

    setOrderProductsAnalytics(_analytics)

  }, [rows]);

  const getPurchaseOrder = () => {
    if (!orderId || orderId == "new") {
      return
    }
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    setLoading(s => s+1)
    httpGetPurchaseOrder(orderId, {signal: newController.signal}).then(res => {
      setOrderInstance(res?.data)
      getPurchaseOrderCustomerCampaignProducts();
      getCustomerEmails(res?.data?.customer);
      getSellerEmails();
      setArchivedBySeller(res?.data?.archived_by_seller);
    }, error => {
      console.error('httpGetPurchaseOrder error:', error);
      if (error?.status === 404) {
        notify({
          type: "danger",
          message: "Tilausta ei löytynyt"
        })
        if (myUser?.is_customer === true) {
          history.push(`/dashboard/price_list_orders/list/purchases/`);
        }
        else {
          history.push(`/dashboard/price_list_orders/list/sales/`);
        }
      }
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const openOrderDelete = () => {
    setDeleteAlert(
      <AlertModal
        title={myUser?.is_customer === false ? 
            t("Hylätäänkö tilaus?") : 
            (myUser?.is_customer === true && orderInstance?.state >= 1 ? 
                t("Peruutetaanko tilaus?") : 
                t("price_list_order_delete_confirm","Poistetaanko tilaus?"))}
        onConfirm={() => handleDeleteOrder()}
        onCancel={() => cancelOrderDelete()}
        showCancel={true}
        cancelBtnText={myUser?.is_customer === false || (myUser?.is_customer === true && orderInstance?.state >= 1) ? 
            t("Ei") : 
            t("price_list_order_delete_cancel","Peruuta")}
        confirmBtnText={myUser?.is_customer === false || (myUser?.is_customer === true && orderInstance?.state >= 1) ? 
            t("Kyllä") : 
            t("price_list_order_delete_confirm","Poista")}
      >
      </AlertModal>
    )
  }
  const cancelOrderDelete = () => {
    setDeleteAlert(null)
  }

  const handleDeleteOrder = () => {
    if (!orderId || orderId == "new") {
      return
    }

    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    setLoading(s => s+1)
    httpDeletePurchaseOrder(orderId, {signal: newController.signal}).then(res => {
      notify({
        type: "success",
        message: "Tilaus poistettu"
      })
      if (myUser?.is_customer === true) {
        history.push(`/dashboard/price_list_orders/list/purchases/`);
      }
      else {
        history.push(`/dashboard/price_list_orders/list/sales/`);
      }
    }, error => {
      console.error('httpDeletePurchaseOrder error:', error);
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const sendRequestForQuotation = () => {
    if (!orderId || orderId == "new") {
      // Return promise reject
      return Promise.reject("No orderId")
    }
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    setLoading(s => s+1)
    return httpSendRequestForQuotation(orderId, {signal: newController.signal}).then(res => {
      notify({
        type: "success",
        message: "Tarjouspyyntö lähetetty"
      })
    }, error => {
      console.error('httpSendRequestForQuotation error:', error);
      if (error?.data?.errorCode?.includes("NO_PRODUCTS_IN_PURCHASE_ORDER")) {
        notify({
          type: "danger",
          message: "Tarjouspyyntöä ei voi lähettää, koska tilauksessa ei ole tuotteita"
        })
      }
      else {
        notify({
          type: "danger",
          message: "Tarjouspyynnön lähetys epäonnistui"
        })
      }
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const saveAndsendRequestForQuotation = () => {
    setConfirmAction(null)
    saveOrder().then(() => {
      sendRequestForQuotation().then(() => {
        getPurchaseOrder();
      })
    })
  }

  const handleSendRequestForQuotation = () => {
    setConfirmAction(
      <AlertModal
        title={t("price_list_order_confirm_request_for_quotation","Lähetä tarjouspyyntö?")}
        onConfirm={() => saveAndsendRequestForQuotation()}
        onCancel={() => cancelConfirmAction()}
        showCancel={true}
        cancelBtnText={t("price_list_order_confirm_action_button_cancel","Peruuta")}
        confirmBtnText={t("price_list_order_confirm_action_button_send","Lähetä")}
      >
      </AlertModal>
    )
  }
  
  const sendAnOfferPurchaseOrder = () => {
    if (!orderId || orderId == "new") {
      // Return promise reject
      return Promise.reject("No orderId")
    }
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    setLoading(s => s+1)
    return httpSendAnOfferPurchaseOrder(orderId, {signal: newController.signal}).then(res => {
      notify({
        type: "success",
        message: "Tarjous lähetetty"
      })
    }, error => {
      console.error('httpSendAnOfferPurchaseOrder error:', error);
      notify({
        type: "danger",
        message: "Tarjouksen lähetys epäonnistui"
      })
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const saveAndSendAnOfferPurchaseOrder = () => {
    setConfirmAction(null)
    saveOrder().then(() => {
      sendAnOfferPurchaseOrder().then(() => {
        getPurchaseOrder();
      });
    })
  }

  const handleSendAnOfferPurchaseOrder = () => {
    setConfirmAction(
      <AlertModal
        title={t("price_list_order_confirm_send_offer","Lähetä tarjous?")}
        onConfirm={() => saveAndSendAnOfferPurchaseOrder()}
        onCancel={() => cancelConfirmAction()}
        showCancel={true}
        cancelBtnText={t("price_list_order_confirm_action_button_cancel","Peruuta")}
        confirmBtnText={t("price_list_order_confirm_action_button_send","Lähetä")}
      >
      </AlertModal>
    )
  }

  const confirmPurchaseOrder = () => {
    if (!orderId || orderId == "new") {
      // Return promise reject
      return Promise.reject("No orderId")
    }
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    setLoading(s => s+1)
    return httpConfirmPurchaseOrder(orderId, {signal: newController.signal}).then(res => {
      notify({
        type: "success",
        message: "Vahvistus lähetetty"
      })
    }, error => {
      console.error('httpConfirmPurchaseOrder error:', error);
      notify({
        type: "danger",
        message: `Vahvistuksen lähetys epäonnistui ${error?.data?.message}`
      })
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const saveAndConfirmOrder = () => {
    setConfirmAction(null)
    saveOrder().then(() => {
      confirmPurchaseOrder().then(() => {
        console.log("confirmPurchaseOrder THEN")
        getPurchaseOrder();
      });
    })
  }

  const handleConfirmOrder = () => {
    setConfirmAction(
      <AlertModal
        title={t("price_list_order_confirm_order","Vahvista tilaus?")}
        onConfirm={() => saveAndConfirmOrder()}
        onCancel={() => cancelConfirmAction()}
        showCancel={true}
        cancelBtnText={t("price_list_order_confirm_action_button_cancel","Peruuta")}
        confirmBtnText={t("price_list_order_confirm_action_button_confirm","Vahvista")}
      >
      </AlertModal>
    )
  }

  const handleOrderChange = ({target}, rowId) => {
    setUnsavedChanges(true)
    setOrderInstance(s => ({
      ...s,
      [target.name]: target.value,
    }))
  }
    
  
  // Table
  
  const isInitialMount = useRef(true);

  useEffect(() => {
    console.log("filters", filters)
  }, [filters])

  const handleFilterSelectChange = ({target}) => {
    const { name, value } = target;
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }

  useEffect(() => {
    setPreHeader(s => ({
      ...s,
      "campaign_fk": {
        content: (
          <select name="campaign_fk" className="form-control" onChange={handleFilterSelectChange}>
            <option value="">{t("price_list_order_filter_all","Ei rajausta")}</option>
            {customerCampaigns && customerCampaigns.map(campaign => {
              return (
                <option value={campaign?.campaign_fk}>{campaign?.label} ({campaign?.campaign_fk})</option>
              )
            })}
          </select>
        )
      },
      "order_amount": {
        content: (
          <select name="order_amount" className="form-control" onChange={handleFilterSelectChange}>
            <option value="">{t("price_list_order_filter_all","Ei rajausta")}</option>
            <option value="1">{t("price_list_order_filter_quantity_more","Määrä > 0")}</option>
            <option value="0">{t("price_list_order_filter_quantity_0","Määrä = 0")}</option>
          </select>
        )
      },
      "seller_amount": {
        content: (
          <select name="seller_amount" className="form-control" onChange={handleFilterSelectChange}>
            <option value="">{t("price_list_order_filter_all","Ei rajausta")}</option>
            <option value="1">{t("price_list_order_filter_quantity_more","Määrä > 0")}</option>
            <option value="0">{t("price_list_order_filter_quantity_0","Määrä = 0")}</option>
          </select>
        )
      },

    }))

  }, [customerCampaigns, filterOptions, getSelectedOptions])

  const getSelectedOptions = useCallback((name) => {
    return filters[name] && filters[name].map(item => {
      return {value: item, label: item}
    })
  }, [filters])
  
  const getCustomerCampaigns = () => {
    // Get customer campaigns == Price lists
    
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    const params = {
      // ...filters,
    }

    setLoading(s => s+1)
    httpGetCustomerCampaigns(params, {signal: newController.signal}).then(res => { 
      let _customerCampaigns=[];
      res?.data.map(obj => {
        _customerCampaigns.push({
          value: obj?.id, 
          label: obj?.name,
          campaign_fk: obj?.campaign_fk,
        })
      })
      setFilterOptions(s => ({
        ...s,
        ["customer_campaigns"]: _customerCampaigns
      }))
      setCustomerCampaigns(_customerCampaigns);
    }, error => {
      console.error('httpGetCustomerCampaigns error:', error);
    }).finally(() => {
      setLoading(s => s-1)
    })
  }
  
  useEffect(() => {
    getCustomerCampaigns();
  }, [])
  
  const getPurchaseOrderCustomerCampaignProducts = () => {

    if (!orderId || orderId == "new") {
      console.log("(!orderId || orderId == 'new')")
      return
    }
    
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    const params = {
      // ...filters,
    }

    setLoading(s => s+1)
    httpGetPurchaseOrderCustomerCampaignProducts(orderId, params, {signal: newController.signal}).then(res => {
      res?.data && res?.data.map(row => {
        row["amount_backup"] = row["amount"];
        row["seller_amount_backup"] = row["seller_amount"];
      })

      setRows(res?.data)
    }, error => {
      console.error('httpGetPurchaseOrderCustomerCampaignProducts error:', error);
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }

    if (orderId && parseInt(orderId) > 0) {
      getPurchaseOrder();
    }
    
  }, [orderId]) // filters, 
  
  const handleCopyOrReverseAmounts = () => {
    rows.map(row => {
        if (myUser?.is_customer === true) {
          if (amountsCopied === false) {
            row["amount"] = row["seller_amount"];
          }
          else {
            row["amount"] = row["amount_backup"];
          }
        }
        else {
          if (amountsCopied === false) {
            row["seller_amount"] = row["amount"];
          }
          else {
            row["seller_amount"] = row["seller_amount_backup"];
          }
        }
    })

    setRows([
      ...rows
    ])

    setAmountsCopied(s => !s)

  }

  const handleOrderProductCopyAmount = (rowId) => {
    rows.map(row => {
      if (row?.id === rowId) {
        if (myUser?.is_customer === true) {
          row["amount"] = row["seller_amount"];
        }
        else {
          row["seller_amount"] = row["amount"];
        }
      }
    })
    setRows([
      ...rows
    ])

  }

  const [preHeader, setPreHeader] = useState({})

  const [headers, setHeaders] = useState([
    { label: t("price_list_order_image","Kuva"), key: "image_url_mcf", sortable:false, visible: true},
    { label: t("price_list_order_campaign_fk","Myyntihinnasto-ID"), key: "campaign_fk", sortable:true, visible: true}, // customer_campaign_fk
    { label: t("price_list_order_product_statusk","Tila"), key: "product_status", sortable:false, visible: true}, 
    { label: t("price_list_order_product_visibility","Näkyvillä"), key: "product_visibility", sortable:false, visible: myUser?.is_customer === false}, 
    { label: t("price_list_order_preorder_id","Ennakkotilaustuote-ID"), key: "preorder_id", sortable:false, visible: true},
    { label: t("price_list_order_erp_id","ERP-ID"), key: "product_id", sortable:false, visible: true},
    { label: t("price_list_order_preorder_id","MCF-päätuotteen ID"), key: "product_id_mcf", sortable:false, visible: true},
    { label: t("price_list_order_preorder_id","MCF-variaatiotuotteen ID"), key: "product_variation_id_mcf", sortable:false, visible: true},
    { label: t("price_list_order_product_link","Tuotekortti"), key: "product_url_mcf", sortable:false, visible: true},
    { label: t("price_list_order_product_name","Nimi"), key: "product_name", sortable:false, visible: true},
    { label: t("price_list_order_product_code","Tuotenumero"), key: "product_code", sortable:false, visible: true},
    { label: t("price_list_order_barcode","Viivakoodi"), key: "barcode", sortable:false, visible: true},
    { label: t("price_list_order_balance","Varastossa vapaana"), key: "balance", sortable:false, visible: myUser?.is_customer === false},
    { label: t("price_list_order_price","Myyntihinta"), key: "product_price", sortable:false, visible: true},
    { label: t("price_list_order_quantity","Määrä"), key: "quantity", sortable:false, visible: true},
    { label: t("price_list_order_order_limit","Tilausrajoitus"), key: "order_limit", sortable:false, visible: true},
    { label: t("price_list_order_discount_price","Alennushinta (sis. ALV)"), key: "price", sortable:false, visible: true},
    { label: t("price_list_order_discount_percent","Alennus-%"), key: "sale_percentage", sortable:false, visible: true},
    { label: t("price_list_order_discount_price","Alennushinta (ALV 0 %)"), key: "sale_price_without_vat", sortable:false, visible: true},
    { label: t("price_list_order_purchase_price","Ostohinta"), key: "purchase_price", sortable:true, visible: myUser?.is_customer === false}, // Seller
    { label: t("price_list_order_purchase_price","Kate"), key: "cross_profit", sortable:true, visible: myUser?.is_customer === false}, // Seller
    { label: t("price_list_order_purchase_price","Kate-%"), key: "cross_profit_percentage", sortable:true, visible: myUser?.is_customer === false}, // Seller
    { label: t("price_list_order_suppliers","Toimittajia"), key: "suppliers", sortable:true, visible: myUser?.is_customer === false}, // Seller
    { label: t("price_list_order_suppliers_product_codes","Toimittajan tuotenumeroita (akt.)"), key: "supplier_codes", sortable:true, visible: myUser?.is_customer === false}, // Seller
    { label: t("price_list_order_moq","MOQ (min–max)"), key: "minimum_order_quantity", sortable:false, visible: true},
    { label: t("price_list_order_batch","Eräkoko (min–max"), key: "batch", sortable:true, visible: myUser?.is_customer === false}, // Seller
    { label: t("price_list_order_copy","Kopio vastapuolen määrä"), key: "copy", sortable:true, visible: true},
    { label: t("price_list_order_order_amount","Tilattava määrä"), key: "order_amount", sortable:true, visible: true},
    { label: t("price_list_order_seller_amount","Myyjän ehdotus"), key: "seller_amount", sortable:true, visible: true},
    { label: t("price_list_order_available","Heti saatavilla"), key: "available", sortable:false, visible: true},
    { label: t("price_list_order_post_delivery","Jälkitoimitus"), key: "post_delivery", sortable:false, visible: true},
    { label: t("price_list_order__mcf_order_number","MCF-tilausnumero"), key: "mcf_order_number", sortable:false, visible: true},
    { label: t("price_list_order_processed","Käsitelty"), key: "processed", sortable:false, visible: true},
  ]);
  
  const setHeaderValue = (headerKey, key, value) => {
    let headersTemp = headers;
    
    const index = headersTemp && headersTemp.findIndex(item => { return item?.key === headerKey });
    headersTemp[index] = {
      ...headersTemp[index],
      [key]: value,
    }
    setHeaders([
      ...headersTemp
    ])
  }

  const [secondHeader, setSecondHeader] = useState();

  useEffect(() => {
    setSecondHeader(
      {
        "order_amount": { content: (<><Button disabled={isOrderConfirmed()} className="btn btn-sm" onClick={() => handleCopyOrReverseAmounts()}><i className="far fa-copy"></i></Button></>), visible: myUser?.is_customer === true}, // Buyer
        "seller_amount": { content: (<><Button disabled={isOrderConfirmed()} className="btn btn-sm" onClick={() => handleCopyOrReverseAmounts()}><i className="far fa-copy"></i></Button></>), visible: myUser?.is_customer === false}, // Seller
      }
    )
  }, [rows])
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const handleOrderProductChange = ({target}, rowId) => {
    setUnsavedChanges(true)
    rows.map(row => {
      if (row?.id === rowId) {
        row[target.name] = target.value;
      }
    })
    setRows([
      ...rows
    ])

  }

  const handleOrderProductCheckboxChange = ({target}, rowId) => {
    setUnsavedChanges(true)
    rows.map(row => {
      if (row?.id === rowId) {
        row[target.name] = !row[target.name];
      }
    })
    setRows([
      ...rows
    ])

  }

  const isProductsAmountsEqualBetweenBuyerSeller = useCallback(() => {
    let isProductsAmountsEqual = true;
    rows.map(row => {
      const buyerAmount = row?.amount ? row?.amount : 0;
      const sellerAmount = row?.seller_amount ? row?.seller_amount : 0;
      if (buyerAmount != sellerAmount) {
        isProductsAmountsEqual = false;
      }
    })
    return isProductsAmountsEqual;
  }, [rows])

  const readyToConfirm = useCallback(() => {
    let ready = true;
    

    if (myUser?.is_customer === true) { // Buyer
      ready = isProductsAmountsEqualBetweenBuyerSeller() && orderInstance?.confirmed_by_supplier === true;
    }
    else { // Seller
      ready = isProductsAmountsEqualBetweenBuyerSeller();
    }

    return ready;
  }, [rows, myUser, orderInstance, isProductsAmountsEqualBetweenBuyerSeller])

  const isOrderConfirmed = useCallback(() => {
    // Have both buyer and seller confirmed the order
    return orderInstance?.confirmed_by_orderer === true && orderInstance?.confirmed_by_supplier === true;
  }, [orderInstance])



  const getTableRows = useCallback(() => {
    let results = [];
    if (rows?.results) {
      results = rows?.results;
    }
    else if (rows) {
      results = rows;
    }

    if (results == null || results.length === 0) {
      return [];
    }

    // TODO Calculate rows values for filters

    // Filter results

    let filter_campaign_fk = 0;
    if (filters?.campaign_fk && filters?.campaign_fk.length > 0) {
      filter_campaign_fk = parseInt(filters?.campaign_fk);
    }

    if (filter_campaign_fk > 0) {
      results = results.filter(row => {
        return row?.campaign_fk === filter_campaign_fk;
      })
    }

    if (filters?.order_amount === "1") {
      results = results.filter(row => {
        return row?.amount > 0;
      })
    }
    else if (filters?.order_amount === "0") {
      results = results.filter(row => {
        return row?.amount === 0;
      })
    }

    if (filters?.seller_amount === "1") {
      results = results.filter(row => {
        return row?.seller_amount > 0;
      })
    }
    else if (filters?.seller_amount === "0") {
      results = results.filter(row => {
        return row?.seller_amount === 0;
      })
    }

    if (filters?.available === "1") {
      results = results.filter(row => {
        return row?.amount < row?.quantity;
      })
    }
    else if (filters?.available === "0") {
      results = results.filter(row => {
        return row?.amount === row?.quantity;
      })
    }

    if (filters?.post_delivery === "1") {
      results = results.filter(row => {
        return row?.amount > row?.quantity;
      })
    }
    else if (filters?.post_delivery === "0") {
      results = results.filter(row => {
        return row?.amount <= row?.quantity;
      })
    }

    return results.map(row => {
      
      const hasVariations = false;
      
      // Tilattu määrä (tuotekoodilla)
      let orderProductAmount = row?.amount ? row?.amount : 0; // order_amount_value
      let orderProductSellerAmount = row?.seller_amount ? row?.seller_amount : 0;

      // Available (heti saatavilla)
      let available = 0;
      if (orderProductAmount > 0) {
        if (orderProductAmount < row?.quantity) {
          available = orderProductAmount;
        }
        else {
          available = row?.quantity;
        }
      }

      let post_delivery = orderProductAmount > row?.quantity ? orderProductAmount - row?.quantity : 0;
      const product_link = row?.product_variation_fk ? row?.product_variation_fk?.url_mcf : row?.product_fk?.url_mcf;
      
      
      return {
        ...row,
        image_url_mcf: row?.product_variation_fk ? (
          <Link href={row?.product_variation_fk?.image_url_mcf} isExternal={true}><img src={row?.product_variation_fk?.image_url_mcf} className="img-thumbnail" /></Link>
        ) : (
          <Link href={row?.product_fk?.image_url_mcf} isExternal={true}><img src={row?.product_fk?.image_url_mcf} className="img-thumbnail" /></Link>
        ),
        product_url_mcf: row?.product_variation_fk ? (
          <Link href={row?.product_variation_fk?.url_mcf} isExternal={true}>Linkki</Link>
        ) : (
          <Link href={row?.product_fk?.url_mcf} isExternal={true}>Linkki</Link>
        ),
        product_id: row?.product_variation_fk ? row?.product_fk?.id + "-" + row?.product_variation_fk?.id : row?.product_fk?.id,
        product_name: row?.product_variation_fk ? row?.product_variation_fk?.name : row?.product_fk?.name,
        product_code: row?.product_variation_fk ? row?.product_variation_fk?.product_code : row?.product_fk?.product_code,
        product_price: row?.product_variation_fk ? row?.product_variation_fk?.price : row?.product_fk?.price,
        purchase_price: row?.product_variation_fk ? row?.product_variation_fk?.purchase_price : row?.product_fk?.purchase_price,
        product_visibility: row?.product_fk ? row?.product_fk?.versions : "",
        product_status: row?.product_variation_fk ? row?.product_variation_fk?.status : row?.product_fk?.status,
        product_id_mcf: row?.product_fk ? row?.product_fk?.id_mcf : "",
        product_variation_id_mcf: row?.product_variation_fk ? row?.product_variation_fk?.id_mcf : "",
        sale_percentage: row?.sale_percentage ? row?.sale_percentage + " %" : "",
        barcode: row?.product_variation_fk ? row?.product_variation_fk?.barcode != "0" ? row?.product_variation_fk?.barcode : "" : row?.product_fk?.barcode != "0" ? row?.product_fk?.barcode : "",
        order_amount: hasVariations === false ? (
          <Input disabled={loading > 0 || myUser?.is_customer === false || isOrderConfirmed()} className="sk_input form-control-sm" type="number" min={0} name="amount" value={orderProductAmount.toString()} onChange={(e) => handleOrderProductChange(e, row?.id)} onWheel={(e) => e.target.blur()} />
        ) : null,
        seller_amount: hasVariations === false ? (
          <Input disabled={loading > 0 || myUser?.is_customer === true || isOrderConfirmed()} className="sk_input form-control-sm" type="number" min={0} name="seller_amount" value={orderProductSellerAmount.toString()} onChange={(e) => handleOrderProductChange(e, row?.id)} onWheel={(e) => e.target.blur()} />
        ) : null,
        copy: (
          <Button disabled={loading || isOrderConfirmed()} className="btn btn-sm" onClick={() => handleOrderProductCopyAmount(row?.id)}><i className="far fa-copy"></i></Button>
        ),
        available: available,
        post_delivery: post_delivery,
        processed: myUser?.is_customer === true ? (
          <Input disabled={loading > 0 || isOrderConfirmed()} className="sk_input form-control-sm" type="checkbox" name="confirmed_by_orderer" checked={row?.confirmed_by_orderer} onChange={(e) => handleOrderProductCheckboxChange(e, row?.id)} onWheel={(e) => e.target.blur()} />
        ) : (
          <Input disabled={loading > 0 || isOrderConfirmed()} className="sk_input form-control-sm" type="checkbox" name="confirmed_by_supplier" checked={row?.confirmed_by_orderer} onChange={(e) => handleOrderProductCheckboxChange(e, row?.id)} onWheel={(e) => e.target.blur()} />
        ),
        
      }
    })
  }, [rows, loading, filters])

  const saveOrder = () => {
    /* docs:
    `order_data=[list(obj)]` 
    purchase order products to be added to the purchase order

    `amount=[int]` 
    desired amount of products

    `seller_amount=[int]` 
    The seller suggested amount

    `batch=[int]` 
    batch size of ordable product

    `customer_campaign_price=[int]` 
    The customer campaign price id in which the purchase order product is assosiated

    `price=[int]` 
    The normal price of the product

    `product=[int]` 
    The id of the desired product

    `product_variation=[int]` 
    The normal price of the product variation

    `mcf_order_number=[int]` 
    The possible mcf order number assosiated to purchase order product. Set and seen only by seller
    */
    const orderData = {};
    rows && rows.map(row => {
      // Add to order products only if amount > 0 or purchase_order_product != null
      if (row?.amount > 0 || row?.seller_amount > 0 || row?.purchase_order_product != null) {
        const orderProduct = {
          amount: row?.amount,
          // batch: 1,
          seller_amount: row?.seller_amount,
          // batch: row?.batch,
          customer_campaign_price: row?.customer_campaign_price_fk, // id,
          // price: row?.price,
          product: row?.product_fk?.id,
          product_variation: row?.product_variation_fk?.id ? row?.product_variation_fk?.id : 0,
          mcf_order_number: row?.mcf_order_number,
          confirmed_by_orderer: row?.confirmed_by_orderer,
          confirmed_by_supplier: row?.confirmed_by_supplier,
        }

        if (row?.product_variation_fk?.id) {
          orderData["p"+row?.product_fk?.id+"v"+row?.product_variation_fk?.id] = orderProduct;
        }
        else {
          orderData["p"+row?.product_fk?.id+"v0"] = orderProduct;
        }
        
      }
    })

    const data = {
      ...orderInstance,
      id: parseInt(orderId),
      order_data: orderData,
      supplier: undefined,
      buyer_email: myUser?.is_customer === true ? orderInstance?.buyer_email : undefined,
      seller_email: myUser?.is_customer === false ? orderInstance?.seller_email : undefined,
    }
    return httpUpdatePurchaseOrder(orderId, data).then(res => {
      notify({
        type: "success",
        message: "Tilaus tallennettu"
      })
    }, error => {
      console.error('httpUpdatePurchaseOrder error:', error);
      notify({
        type: "error",
        message: "Tilauksen tallennus epäonnistui"
      })
    })
  }

  const handleSave = () => {
    saveOrder();
  }
    

  return (
    <>
      <Row> 
        <Col>
          <Breadcrumb className="mb-0" listClassName="breadcrumb-links">
            <BreadcrumbItem>
                {t("flowm8_price_list_order_purchase_orders","Ostotilaukset")}
            </BreadcrumbItem>
            {orderId == "new" ? (
              <BreadcrumbItem>
              {t("flowm8_price_list_order_create_order","Luo tilaus")}
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>
              {t("flowm8_price_list_order_edit_order","Muokkaa tilausta")}
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          { orderInstance?.state === 1 ? (
          <PageHeader title={t("flowm8_price_list_order_title_state_1","Valmistele tilaus")}></PageHeader>
          ) : null }
          { orderInstance?.state > 1 ? (
          <>
            <PageHeader title={t("flowm8_price_list_order_title_state_2","Käsittele tilaus")}></PageHeader>
            {archivedBySeller && (
              <Typography className="text-danger">
                <strong>Tämä Ostotilaus on hylätty myyjän toimesta</strong>
              </Typography>
            )}
          </>
          ) : null }
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <TableColumnToggle tableName="PriceListOrder" userId={myUser?.id} headers={headers} setHeaders={setHeaders} setHeaderValue={setHeaderValue} />
            </div>
          </div>
        </Col>
      </Row>
      
      <Row> {/* className="d-flex justify-content-between mb-3" */}
        <Col className="col-sm-12 col-lg-4">
          <div className="d-flex flex-row">
            <div className="p-2">
              <Typography bold>{t("Tavarantoimittaja")}</Typography>
              <Typography>Tradesoft Oy</Typography>
            </div>
            <div className="p-2">
              <Typography bold>{t("Näkymässä / hinnastoilla")}</Typography>
              <Typography>{t("Päätuotteita (ID)")}: {orderProductsAnalytics?.price_list_products_count}</Typography>
              <Typography>{t("Variaatiotuotteita (ID)")}: {orderProductsAnalytics?.price_list_variation_products_count}</Typography>
            </div>
          </div>
         
        </Col>
        <Col className="col-sm-12 col-lg-4">
          <div className="d-flex flex-row">
            <div className="p-2">
                <Typography variant="h2" bold>{t("Tilauksen tiedot")}</Typography>
                <Typography>{t("Summa")} (ALV 0% / sis. ALV): {orderInstance?.total_order_value_without_vat} / {orderInstance?.total_order_value}</Typography>
                <Typography>{t("Päätuotteita")} (ID / kpl): {orderInstance?.products_count}</Typography>
                <Typography>{t("Variaatiotuotteita")} (ID / kpl): {orderInstance?.product_variations_count}</Typography>
                {/* <Typography>{t("Toimittajan ehdotus")} (ALV 0% / sis. ALV):</Typography> */}
              <Typography variant="h2" bold>{t("Lisätiedot")}</Typography>
              <Input type="textarea" name="info" onChange={handleOrderChange} value={orderInstance?.info} />
            </div>
          </div>
          
          <div className="d-flex flex-row">
            <div className="p-2">
                <Typography variant="h3" bold>{t("Ostajan sähköposti-ilmoitukset")}</Typography>
                <Typography>
                  <select name="buyer_email" className="form-control" onChange={handleOrderChange} value={orderInstance?.buyer_email} disabled={myUser?.is_customer === false}>
                    <option value="">{t("price_list_order_buyer_email","Valitse sähköposti")}</option>
                    { customerEmails && customerEmails.map(customerEmail => {
                      return (
                        <option value={customerEmail?.email}>{customerEmail?.email}</option>
                      )
                    })}
                  </select>
                  {/*
                  <Input type="email" name="buyer_email" onChange={handleOrderChange} value={orderInstance?.buyer_email} disabled={myUser?.is_customer === false} />
                  */}
                </Typography>
            </div>
            <div className="p-2">
                <Typography variant="h3" bold>{t("Myyjän sähköposti-ilmoitukset")}</Typography>
                <Typography>
                  
                  <select name="seller_email" className="form-control" onChange={handleOrderChange} value={orderInstance?.seller_email} disabled={myUser?.is_customer === true}>
                    <option value="">{t("price_list_order_seller_email","Valitse sähköposti")}</option>
                    { sellerEmails && sellerEmails.map(sellerEmail => {
                      return (
                        <option value={sellerEmail?.email}>{sellerEmail?.email}</option>
                      )
                    })}
                  </select>
                  
                  {/*
                  <Input type="email" name="seller_email" onChange={handleOrderChange} value={orderInstance?.seller_email} disabled={myUser?.is_customer === true} />
                  */}
                </Typography>
            </div>
          </div>
         
        </Col>
        <Col className="col-sm-12 col-lg-4 text-right">
          { orderInstance?.state === 1 ? (
          <Button className="mb-2" color="primary" onClick={handleSave} disabled={loading || isOrderConfirmed() || (orderInstance?.state < 2 && myUser?.is_customer === false)}>{t("price_list_order_button_save","Tallenna luonnos")}</Button>
          ) : null }
          
          { orderInstance?.state > 0 ? (
            <Button className="mb-2" color="danger" onClick={openOrderDelete} 
              disabled={loading || (myUser?.is_customer === true && orderInstance?.confirmed_by_orderer && orderInstance?.confirmed_by_supplier && orderInstance?.archived_by_seller === false)}>
              {myUser?.is_customer === false ? 
                t("Hylkää myyntitilaus") : 
                (myUser?.is_customer === true && orderInstance?.state >= 1 && archivedBySeller === false? 
                  t("Peruuta ostotilaus") : 
                  t("price_list_order_button_delete","Poista ostotilaus"))}
            </Button>
          ) : null }

          { myUser?.is_customer === true && orderInstance?.state >= 1 && orderInstance?.state < 4 ? (
            <Button className="mb-2" color="success" onClick={handleSendRequestForQuotation} disabled={loading || isOrderConfirmed() || readyToConfirm() || archivedBySeller}>{t("price_list_order_button_send","Lähetä tarjouspyyntö")}</Button>
          ) : null }
          { myUser?.is_customer === false && orderInstance?.state >= 1 && orderInstance?.state < 4 ? (
            <Button className="mb-2" color="success" onClick={handleSendAnOfferPurchaseOrder} 
              disabled={loading || isOrderConfirmed() || readyToConfirm() || archivedBySeller || (orderInstance?.state < 2)}>
              {t("price_list_order_button_send","Lähetä tarjous")}
            </Button>
          ) : null }

          { orderInstance?.state > 1 && orderInstance?.state < 4 ? (
          <Button className="mb-2" color="success" onClick={handleConfirmOrder} disabled={readyToConfirm() === false || loading || isOrderConfirmed() || archivedBySeller}>{t("price_list_order_button_confirm","Vahvista tilaus")}</Button>
          ) : null }

        </Col>
      </Row>
      
      {/*
      <Row className="mb-3">
        <Col className="mb-5">
          <Typography variant="h2" bold>{t("Suodata")}</Typography>
          <div className="d-flex flex-row">
            <div className="p-2">
              <SelectMulti label={t("sales_price_lists_filter_creator","Hinnasto-ID")} placeholder="Kaikki" name="customer_campaigns" values={filters} setFieldValue={setFilterValue} defaultValues={getSelectedOptions("customer_campaigns")} options={getFilterOptions("customer_campaigns")} />
            </div>
          </div>
        </Col>
      </Row>
      */}
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        preHeader={preHeader}
        headers={headers}
        secondHeader={secondHeader}
        rows={getTableRows()}
        loading={loading}
        pagination={false}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        filters={filters}
        setFilters={setFilters}
        tableStickyHeader={true}
      />

      {confirmAction}
      {deleteAlert}
    
    </>
  );
}

export default PriceListOrder;
