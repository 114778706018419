import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { 
  AlertModal, 
  Button, 
  Link, 
  Modal,
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input } from "stories/forms";
import {
  httpGetProducts,
  httpImportProductsFromMCF,
  httpExportProductsToMCF,
  httpImportProductVariationsFromMCF,
  httpExportProductVariationsToMCF
} from "services/products"
import { httpGetBrands } from "services/brands"
import { httpGetCategories } from "services/categories"
import { httpGetSuppliers } from "services/suppliers"
import TableColumnToggle from "components/TableColumnToggle/TableColumnToggle"
import { useNotification } from "stories/components/Notification"
import ProductsExport from "views/dashboard/ProductsExport/ProductsExport"

const ProductsList = ({history}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();
  const [loadingMcf, setLoadingMcf] = useState(false);
  const [controller, setController] = useState(null);
  
  useEffect(() => {
    // Fetch data when the component mounts
    getProducts();

    // Cleanup function to cancel the request if the component unmounts
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);

  // Brands
  
  const [brands, setBrands] = useState();
   
   useEffect(() => {
     getBrands();
   }, [])
   
  const getBrands = () => {
    
    httpGetBrands({ordering:"name"}).then(res => {
      setBrands(res?.data)
    })
  }
  
  const getBrandOptions = useCallback(() => {
    return brands && brands.map(brand => {
      return (
        <option value={brand.id}>{brand.name}</option>
      )
    })
  }, [brands])
  
  // Categories
  
  const [categories, setCategories] = useState([])
   
  useEffect(() => {
    getCategories()
  }, [])
   
  const getCategories = () => {
    httpGetCategories({ordering:"name"}).then(res => {
      setCategories(res.data)
    })
  }
   
  const getCategoryOptions = useCallback(() => {
    return categories && categories.map(category => {
      return (
        <option value={category.id}>{category.name}</option>
      )
    })
  }, [categories])
  
  // Suppliers
  
  const [suppliers, setSuppliers] = useState([])
   
  useEffect(() => {
    getSuppliers()
  }, [])
   
  const getSuppliers = () => {
    httpGetSuppliers({ordering:"name"}).then(res => {
      setSuppliers(res.data)
    })
  }
   
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option value={supplier.id}>{supplier.name}</option>
      )
    })
  }, [suppliers])
  
  // Table
  const [loading, setLoading] = useState(0)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "name",
  })
  const isInitialMount = useRef(true);
  const [searchField, setSearchField] = useState()
  
  const getProducts = () => {
    
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    const params = {
      ...filters,
      supplier: filters?.supplier ? filters?.supplier : undefined,
    }

    setLoading(s => s+1)
    httpGetProducts(params, {signal: newController.signal}).then(res => {
      setRows(res?.data)
    }, error => {
      console.error('httpGetProducts error:', error);
    }).finally(() => {
      setLoading(s => s-1)
    })
  }
  
  /*
  useEffect(() => {
    getProducts();
  }, [])
  */
  
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getProducts();
    }
  }, [filters])
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }
  const handleSearchChange = ({ target }) => {
    const { value } = target;
    setSearchField(value)
  }
  const handleSearchBlur = () => {
    setFilters(s => ({
      ...s,
      search: searchField,
    }))
  }
  const handleSearchKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setFilters(s => ({
        ...s,
        search: searchField,
      }))
    }
  }
  
  // Import / Export products
  
  const [confirmImportAlert, setConfirmImportAlert] = useState();
  const [confirmExportAlert, setConfirmExportAlert] = useState();
  
  const handleImportProductsFromMCF = () => {
    setConfirmImportAlert(
      <AlertModal
        title={t("Confirm import")}
        onConfirm={() => executeImportProductsFromMCF()}
        onCancel={() => setConfirmImportAlert(null)}
        showCancel={true}
        cancelBtnText={t("Cancel")}
        confirmBtnText={t("Confirm")}
      />
    )
  }
  const handleExportProductsToMCF = () => {
    setConfirmExportAlert(
      <AlertModal
        title={t("Confirm export")}
        onConfirm={() => executeExportProductsToMCF()}
        onCancel={() => setConfirmExportAlert(null)}
        showCancel={true}
        cancelBtnText={t("Cancel")}
        confirmBtnText={t("Confirm")}
      />
    )
  }
  
  const executeImportProductsFromMCF = () => {
    setConfirmImportAlert(null);
    if (loadingMcf === true) {
      return;
    }
    setLoadingMcf(true)
    httpImportProductsFromMCF().then(res => {
      if (res.status === 200) {
        httpImportProductVariationsFromMCF().then(res2 => {
          if (res2.status === 200) {
            // OK
            notify({ type: "success", title:t("products_list_mcf_import","Tuonti onnistui"), message:""})
          }
          else {
            notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_import_error","Tuonti epäonnistui"), message:JSON.stringify(res?.data)})
          }
        }, (errors) => {
          notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_import_error","Tuonti epäonnistui"), message:JSON.stringify(errors?.data)})
        }).finally(() => {
          setLoadingMcf(false)
        })
      }
      else {
        notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_import_error","Tuonti epäonnistui"), message:JSON.stringify(res?.data)})
        setLoadingMcf(false)
      }
    }, (errors) => {
      notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_import_error","Tuonti epäonnistui"), message:JSON.stringify(errors?.data)})
      setLoadingMcf(false)
    })
  }
  
  const executeExportProductsToMCF = () => {
    setConfirmExportAlert(null);
    if (loadingMcf === true) {
      return;
    }
    setLoadingMcf(true)
    httpExportProductsToMCF().then(res => {
      if (res.status === 200) {
        httpExportProductVariationsToMCF().then(res2 => {
          if (res2.status === 200) {
            // OK
            notify({ type: "success", title:t("products_list_mcf_export","Vienti onnistui"), message:""})
          }
          else {
            // Error
            notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_export_error","Vienti epäonnistui"), message:JSON.stringify(res?.data)})
          }
        }, (errors) => {
          notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_export_error","Vienti epäonnistui"), message:JSON.stringify(errors?.data)})
        }).finally(() => {
          setLoadingMcf(false)
        })
      }
      else {
        notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_export_error","Vienti epäonnistui"), message:JSON.stringify(res?.data)})
        setLoadingMcf(false)
      }
    }, (errors) => {
      notify({ type: "danger", autoDismiss: -1, title:t("products_list_mcf_export_error","Vienti epäonnistui"), message:JSON.stringify(errors?.data)})
      setLoadingMcf(false)
    })
  }
  
  const [headers, setHeaders] = useState([
    /*{ label: "", key: "actions" }, */ // empty at the moment
    { label: t("ERP-id"), key: "id", sortable:false, visible: true},
    { label: t("MCF-id"), key: "id_mcf", sortable:false, visible: true},
    { label: t("Nimi"), key: "name", sortable:true, sortingKey: "name", visible: true},
    { label: t("Tuotenumero"), key: "product_code", visible: true},
    { label: t("Toimittajan tuo.nro"), key: "product_supplier_codes", visible: true},
    { label: t("Ostohinta"), key: "purchase_price", sortable:true, sortingKey: "purchase_price", visible: true},
    { label: t("Myyntihinta"), key: "price", sortable:true, price: "name", visible: true},
    { label: t("Varastossa vapaana"), key: "stock_item_balance", sortable:false, visible: true},
    { label: t("Saatavuus"), key: "stock_item_availability", sortable:false, visible: true},
    { label: t("Kertaa vuodessa"), key: "stock_item_times_year", sortable:false, visible: true},
    { label: t("Varaston kesto 3kk"), key: "stock_item_duration_3month", sortable:false, visible: true},
    { label: t("Hälytysraja"), key: "stock_item_balance_limit", sortable:false, visible: true},
  ]);
  
  const setHeaderValue = (headerKey, key, value) => {
    let headersTemp = headers;
    
    const index = headersTemp && headersTemp.findIndex(item => { return item?.key === headerKey });
    headersTemp[index] = {
      ...headersTemp[index],
      [key]: value,
    }
    setHeaders([
      ...headersTemp
    ])
  }
  
  const handleNew = () => {
    history.push(`/dashboard/products/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/products/edit/${id}/basic`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const results = rows?.results;
    if (results == null || results.length === 0) {
      return [];
    }
    
    return results.map(row => {
      
      // If no stock_item or stock_item.enabled is false (limitless/rajoittamaton) show empty ""
      const stock_item_balance = row?.stock_item && row?.stock_item?.enabled === true ? row?.stock_item?.balance : "";
      
      return {
        id: row?.id,
        name: (
          <>
            <Typography bold>
              <Link to={`/dashboard/products/edit/${row?.id}/basic`}>
              {row?.name ? row?.name : t("noname")}
              </Link>
            </Typography>
            <Typography variant="small">{row?.variations && row?.variations.length > 0 ? `${t("Variaatioita")}: ${row?.variations.length}` : ""}</Typography>
          </>
        ),
        product_code: (
          <>
            <Typography>{row?.product_code}</Typography>
          </>
        ),
        product_supplier_codes: (
          <>
            <Typography>
              {Array.isArray(row?.product_supplier_codes) ? 
                row?.product_supplier_codes.map(c => c.name).join(", ") : 
                ""}
            </Typography>
          </>
        ),
        purchase_price: (
          <>
            <Typography>{row?.purchase_price}</Typography>
          </>
        ),
        price: (
          <>
            <Typography>{row?.price}</Typography>
          </>
        ),
        stock_item_balance: (
          <>
            <Typography>{stock_item_balance}</Typography>
          </>
        ),
        stock_item_balance_limit: (
          <>
            <Typography>{row?.stock_item?.balance_limit}</Typography>
          </>
        ),
        id_mcf: (
          <>
            <Typography>{row?.id_mcf}</Typography>
          </>
        ),
        actions: (
          <>
            {/*
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="eye" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="exclamation" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
            */}
          </>
        ),
      }
    })
  }, [rows])

  // Modal: Excel export
  const [modalExcelExportOpen, setModalExcelExportOpen] = useState(false)
  
  const toggleModalExcelExportOpen = () => {
    setModalExcelExportOpen(s => !s);
  }
  
  const handleOpenModalExcelExport = () => {
    setModalExcelExportOpen(true);
  }
  const resolveModalExcelExport = () => {
    setModalExcelExportOpen(false);
  }

  const rejectModalExcelExport = () => {
    setModalExcelExportOpen(false);
  }

  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Tuotteet")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            {/*
            <div className="p-2">
              <Typography variant="small">
                <Link loading={loadingMcf} variant="link" onClick={handleExportProductsToMCF}>
                  {t("Vie tiedot (MCF)")}
                </Link>
              </Typography>
            </div>
            <div className="p-2">
              <Typography variant="small">
                <Link loading={loadingMcf} variant="link" onClick={handleImportProductsFromMCF}>
                  {t("Tuo tiedot (MCF)")}
                </Link>
              </Typography>
            </div>
            */}
            <div className="p-2">
              <TableColumnToggle tableName="ProductsList" userId={myUser?.id} headers={headers} setHeaders={setHeaders} setHeaderValue={setHeaderValue} />
            </div>
          </div>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          <Button disabled color="warning" onClick={handleNew}>{t("products_list_import_excel_button","Tuo Excel")}</Button>
          <Button color="warning" onClick={handleOpenModalExcelExport}>{t("products_list_export_excel_button","Vie Excel")}</Button>
            {/* loading={loading} disabled={loading}  */}
        </Col>
        <Col className="text-right">
          <Button color="success" onClick={handleNew}>{t("products_list_create_product","Lisää tuote")}</Button>
        </Col>
      </Row>
      
      <Row className="mb-3">
          <Col>
            <Input 
              label={t("Nimi")} 
              type="text" 
              name="search" 
              onChange={handleSearchChange} 
              value={searchField} 
              onBlur={handleSearchBlur}
              onKeyUp={handleSearchKeyUp}
            />
          </Col>

          <Col>
            <Input label={t("Toimittaja")} type="select" name="supplier" onChange={handleFilterChange} value={filters?.supplier}>
              <option value="">{t("Kaikki")}</option>
              {getSupplierOptions()}
            </Input>
          </Col>
          
          <Col>
            <Input label={t("Tuotekategoria")} type="select" name="category" onChange={handleFilterChange} value={filters?.category}>
              <option value="">{t("Kaikki")}</option>
              {getCategoryOptions()}
            </Input>
          </Col>

          <Col>
            <Input label={t("Tuotemerkki")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
              <option value="">{t("Kaikki")}</option>
              {getBrandOptions()}
            </Input>
          </Col>
            
        
        {/*
        <Col className="col-12 col-sm-12 col-md-auto mb-3">
          
          <Row>
            <Col>
              <Input disabled label={t("Tyyppi")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
                <option value="">{t("Kampanja")}</option>
              </Input>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Input disabled label={t("Vakiohaut")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
                <option value="">{t("Loppunut varastosta")}</option>
              </Input>
            </Col>
          </Row>
          
        </Col>
        
        
        <Col className="col-12 col-sm-12 col-md-auto mb-3">
          
          <Row>
            <Col>
              <Input disabled label={t("Varasto")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
                <option value="">{t("Vähän jäljellä")}</option>
              </Input>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Input disabled label={t("Myynti")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
                <option value="">{t("Edelliset 3kk")}</option>
              </Input>
            </Col>
          </Row>
          
        </Col>
        */}
        

      </Row>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        filters={filters}
        setFilters={setFilters}
        tableStickyHeader={true}
      />

      {/* MCF import/export */}
      {confirmImportAlert}
      {confirmExportAlert}
      
      {/* Excel import/export */}
      <Modal
        title={t("products_list_excel_export_modal_title","Vie tuotteet")}
        isOpen={modalExcelExportOpen}
        size="modal-xl"
        toggleModal={toggleModalExcelExportOpen}
      >
        <ProductsExport
          handleResolve={resolveModalExcelExport}
          handleReject={rejectModalExcelExport}
          brands={brands}
          categories={categories}
          loading={loading}
          setLoading={setLoading}
          rows={rows}
          filters={filters}
          setFilters={setFilters}
          searchField={searchField}
          setSearchField={setSearchField}
          suppliers={suppliers}
        />
      </Modal>
    </>
  );
}

export default ProductsList;
